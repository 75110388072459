import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { CBOKeys as SharedCBOKeys } from '@shared/services/src/Cbos.api'

// CBOs Query Key factory
export const CBOKeys = {
  // ['cbos', cboId, 'rule-sets']
  ruleSets: (cboId) => [...SharedCBOKeys.cbo(cboId), 'rule-sets'],

  // ['cbos', cboId, 'providers', query]
  providers: (cboId, query) => [...SharedCBOKeys.cbo(cboId), 'providers', query],

  // ['cbos', cboId, 'gaps', query]
  gaps: (cboId, query) => [...SharedCBOKeys.cbo(cboId), 'gaps', query],

  // ['cbos', cboId, 'weekly-gaps', query]
  weeklyGaps: (cboId, query) => [...SharedCBOKeys.cbo(cboId), 'weekly-gaps', query],

  // ['cbos', cboId, 'contracted-pharmacies', query]
  contractedPharmacies: (cboId, query) => [...SharedCBOKeys.cbo(cboId), 'contracted-pharmacies', query],

  // ['cbos', cboId, 'metrics']
  metrics: (cboId) => [...SharedCBOKeys.cbo(cboId), 'metrics'],
}

const CbosApi = {
  gaps: (cboId, query) =>
    api.get(QS.stringifyUrl({ url: `/cbos/${cboId}/providers/availability_gaps`, query })).then((res) => mapGaps(res.data)),
  weeklyGaps: (cboId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/cbos/${cboId}/providers/weekly_gaps`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  providers: (cboId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/cbos/${cboId}/providers`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== RULE-SETS =====================
  ruleSets: (cboId, config) => {
    return api.get(`/cbos/${cboId}/rule_sets`, config).then((res) => {
      if (!res.data.data) return null
      return mapRuleSets(res.data)
    })
  },
  updateRuleSets: (cboId, data) => api.patch(`/cbos/${cboId}/rule_sets`, data).then((res) => mapRuleSets(res.data)),

  // ============= CONTRACTED PHARMACIES ===============
  contractedPharmacies: (cboId, query) => {
    return api
      .get(QS.stringifyUrl({ url: `/cbos/${cboId}/contracted_pharmacies`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },

  // ================== METRICS ==================
  metrics: (cboId) => api.get(`/cbos/${cboId}/metrics`).then((resp) => resp.data),

  prioritize: (cboId, data) =>
    api.patch(`/cbos/${cboId}/priority_by_state`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  removePriority: (cboId, data) =>
    api
      .delete(`/cbos/${cboId}/priority_by_state`, { data })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default CbosApi

function mapGaps(data) {
  const appointments = Object.entries(data.appointments).reduce((acc, [key, value]) => {
    acc[key] = deserialize(value, { transformKeys: 'camelCase' })?.data ?? []
    return acc
  }, {})

  const availabilities = Object.entries(data.availabilities).reduce((acc, [key, value]) => {
    acc[key] = deserialize(value, { transformKeys: 'camelCase' })?.data ?? []
    return acc
  }, {})

  return {
    appointments,
    availabilities,
    providers: deserialize(data.providers, { transformKeys: 'camelCase' })?.data ?? [],
    gaps: deserialize(data.gaps, { transformKeys: 'camelCase' })?.data ?? [],
  }
}

function mapRuleSets(data) {
  const parsedData = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
  return {
    grant340BInformation: JSON.parse(parsedData?.grant340BInformation ?? '{}'),
    additionalServices: JSON.parse(parsedData?.additionalServices ?? '{}'),
    patientCare: JSON.parse(parsedData?.patientCare ?? '{}'),
    financials: JSON.parse(parsedData?.financials ?? '{}'),
    contacts: JSON.parse(parsedData?.contacts ?? '[]'),
    prescriptionPartners: JSON.parse(parsedData?.prescriptionPartners ?? '[]'),
  }
}
