import { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useMe } from '@shared/providers/src/MeProvider'
import { AuthUtils, BuildEnv } from '@shared/utils'

import { LogoutOutlinedIcon, SettingOutlinedIcon, UserOutlinedIcon } from '@icons'
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui-components'
import Avatar from '@components/Avatar'
import MainCard from '@components/MainCard'
import Transitions from '@components/Transitions'

const styles = {
  button: (theme) => ({
    p: 0.25,
    borderRadius: 1,
    '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.secondary.dark}`,
      outlineOffset: 2,
    },
  }),
  paper: (theme) => ({
    boxShadow: theme.customShadows.z1,
    width: 290,
    minWidth: 240,
    maxWidth: 290,
    [theme.breakpoints.down('md')]: {
      maxWidth: 250,
    },
  }),
  tab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  tabIcon: {
    marginBottom: 0,
    marginRight: '10px',
  },
  tabList: {
    p: 0,
    '& .MuiListItemIcon-root': { minWidth: 32 },
  },
}

export default function Profile() {
  const me = useMe()

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
  }

  return (
    <Box id="my-profile" sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        ref={anchorRef}
        onClick={handleToggle}
        sx={[styles.button, { bgcolor: open ? 'grey.300' : 'transparent' }]}
        aria-label="open profile"
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        data-testid="profile-toggle"
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar user={me} size="sm" />
          {!matchDownSm && (
            <Stack alignItems="flex-start">
              <Typography data-testid="profile-name">{me.fullName}</Typography>
              <Typography data-testid="profile-email" variant="subtitle2" color="text.secondary">
                {me?.email}
              </Typography>
            </Stack>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [0, 9] } }] }}
        data-testid="profile-popper"
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper sx={styles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 2, pb: 1 }}>
                      <Stack direction="row" spacing={1.25} alignItems="center">
                        <Avatar user={me} size="sm" />
                        <Stack>
                          <Typography data-testid="profile-name">{me?.fullName}</Typography>
                          <Typography data-testid="profile-email" variant="subtitle2" color="textSecondary">
                            {me?.email}
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                    {open && (
                      <List component="nav" sx={styles.tabList}>
                        <ListItemButton component={RouterLink} to="/app/profile" data-testid="view-profile" onClick={() => setOpen(false)}>
                          <ListItemIcon>
                            <UserOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Profile" />
                        </ListItemButton>
                        <ListItemButton onClick={() => AuthUtils.logout()} data-testid="logout">
                          <ListItemIcon>
                            <LogoutOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </ListItemButton>
                        {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && (
                          <>
                            <Divider />
                            <ListItemButton
                              onClick={() => setOpen(false)}
                              component="a"
                              href={`${import.meta.env.VITE_URL}/environment`}
                              target="_blank"
                              rel="noopener"
                              data-testid="environment"
                            >
                              <ListItemIcon>
                                <SettingOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Environment (non-prod)" />
                            </ListItemButton>
                          </>
                        )}
                      </List>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}
