import { useMemo } from 'react'
import { range } from 'lodash'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { CloseOutlinedIcon, FileAddOutlinedIcon, MoreOutlinedIcon } from '@icons'
import { Button, ButtonGroup, Chip, Divider, Drawer, IconButton, LoadingButton, Stack, Typography } from '@mui-components'
import LinearProgress from '@components/LinearProgress'
import MainCard from '@components/MainCard'
import SearchInput from '@components/SearchInput'

import SOP from './SOP'
import { LIMIT, useSOPs, useSOPsFilters } from './SOPs.hooks'

const styles = {
  card: {
    border: 'none',
    borderRadius: 0,
    height: '100vh',
    '& .MuiCardHeader-root': {
      color: 'background.paper',
      bgcolor: 'primary.main',
      '& .MuiTypography-root': { fontSize: '1rem' },
    },
  },
}

export default function SOPsDrawer({ open, onClose }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: 1300 }}
      PaperProps={{ sx: { width: 600 } }}
      data-testid="drawer-sops"
    >
      <MainCard
        title="SOPs"
        secondary={
          <IconButton shape="rounded" color="inherit" onClick={onClose} data-testid="close-sops-drawer">
            <CloseOutlinedIcon />
          </IconButton>
        }
        sx={styles.card}
        content={false}
        data-testid="sops-drawer"
      >
        <Content />
      </MainCard>
    </Drawer>
  )
}

function Content() {
  const [{ sopsSearch, sopsPolicy, sopsCategories }, updateFilters] = useSOPsFilters()
  const { data, isPending, isRefreshing, fetchNextPage, hasNextPage, isFetchingNextPage } = useSOPs({
    limit: LIMIT,
    search_term: sopsSearch,
    policy: sopsPolicy,
    categories: sopsCategories,
  })

  const policies = useLookup(Lookup.SOPPolicies)
  const categories = useLookup(Lookup.SOPCategories)

  const categoryMap = useMemo(() => {
    return sopsCategories.reduce((acc, k) => ({ ...acc, [k]: true }), {})
  }, [sopsCategories])

  const hasSearch = Boolean(sopsSearch) || Boolean(sopsPolicy) || sopsCategories.length > 0
  const isEmpty = !isPending && !hasSearch && data.length === 0
  const isEmptySearch = !isPending && hasSearch && data.length === 0
  const hasData = !isPending && !isEmpty && !isEmptySearch

  return (
    <Stack sx={{ height: '100%', overflowY: 'auto', pb: 10 }}>
      <LinearProgress loading={isRefreshing} color="warning" />
      <Stack p={2}>
        <Stack spacing={2}>
          <SearchInput
            placeholder="Search on 'Enter'"
            searchOnEnter
            value={sopsSearch}
            onChange={(sopsSearch) => updateFilters({ sopsSearch })}
            sx={{ backgroundColor: 'background.paper' }}
          />
          <Stack spacing={1}>
            <Typography variant="body2">Policy Section</Typography>
            <ButtonGroup>
              {policies.map((label) => (
                <Button
                  key={label}
                  variant={label === sopsPolicy ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => {
                    if (label === sopsPolicy) {
                      return updateFilters({ sopsPolicy: undefined })
                    } else {
                      return updateFilters({ sopsPolicy: label })
                    }
                  }}
                  data-testid={`sop-policy_${label}`}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body2">Category</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {categories.map((category) => {
                const value = Boolean(categoryMap[category])
                return (
                  <Category
                    key={category}
                    label={category}
                    value={value}
                    onChange={(turnedOn) => {
                      if (turnedOn) {
                        updateFilters({ sopsCategories: sopsCategories.concat(category) })
                      } else {
                        updateFilters({ sopsCategories: sopsCategories.filter((k) => k !== category) })
                      }
                    }}
                    data-testid={`sop-category_${category}`}
                    data-test-active={value}
                  />
                )
              })}
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant="h4">Documents</Typography>
              <RoleGuard allowed={[UserRole.Admin]}>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<FileAddOutlinedIcon />}
                  onClick={() => updateFilters({ sops: 'create' })}
                  data-testid={`sop-create`}
                >
                  Add
                </Button>
              </RoleGuard>
            </Stack>
            <Divider sx={{ mb: -2 }} />
            {isPending && (
              <Stack>
                {range(5).map((i) => (
                  <SOP.Loading key={i} />
                ))}
              </Stack>
            )}
            {isEmpty && <Empty data-testid="sops-empty">No SOPs have been added yet</Empty>}
            {isEmptySearch && (
              <Empty data-testid="sops-not-found">We could not find a SOP that matches, please try a different search</Empty>
            )}
            {hasData && (
              <Stack>
                {data.map((sop) => (
                  <SOP key={sop.id} data={sop} onSelect={() => updateFilters({ sops: 'view', sopId: sop.id })} />
                ))}
              </Stack>
            )}
            {hasNextPage && (
              <LoadingButton
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
                data-testid="sops-load-more"
              >
                more
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

function Empty({ children, ...rest }) {
  return (
    <Stack p={2} alignItems="center" justifyContent="center" {...rest}>
      <Typography variant="h5" color="text.secondary">
        {children}
      </Typography>
    </Stack>
  )
}

function Category({ value = false, onChange, ...rest }) {
  return <Chip variant={value ? 'combined' : 'outlined'} color={value ? 'primary' : 'default'} onClick={() => onChange(!value)} {...rest} />
}
