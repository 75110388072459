import useUserSetting from '@shared/hooks/src/useUserSetting'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserSettingName } from '@shared/utils'

import { StarFilledIcon, StarOutlinedIcon } from '@icons'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui-components'
import Avatar from '@components/Avatar'
import LinearProgress from '@components/LinearProgress'

import { usePriorityToggle } from './CBOsSupportMapDialog.hooks'

export default function StateCBOsDetailsDialog({ state, cbos = [], open, onClose }) {
  const me = useMe()
  const accessRights = useUserSetting(me, UserSettingName.AccessRights)
  const canToggle = me.superAdmin || accessRights?.value?.cbo_rule_set === true

  const toggle = usePriorityToggle(state)

  const onToggle = (cbo) => toggle.mutate(cbo)

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <LinearProgress loading={toggle.isPending} />
      <DialogTitle onClose={onClose}>State: {state}</DialogTitle>
      <DialogContent dividers>
        <List>
          {cbos.map((cbo) => (
            <ListItem
              key={cbo.id}
              secondaryAction={
                cbo.isFullyCovered && (
                  <IconButton edge="end" color="warning" disabled={toggle.isPending} onClick={canToggle ? () => onToggle(cbo) : undefined}>
                    {cbo.prioritized ? <StarFilledIcon /> : <StarOutlinedIcon />}
                  </IconButton>
                )
              }
            >
              <ListItemAvatar>
                <Avatar.CBO data={cbo} />
              </ListItemAvatar>
              <ListItemText primary={cbo.name} secondary={cbo.isFullyCovered ? 'Fully covered' : cbo.counties?.join(', ')} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}
