import { EditorContent, useEditor } from '@tiptap/react'
import QS from 'query-string'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { clipboardTextParser, richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { PossibleRedirects } from '@shared/utils'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack, Typography } from '@mui-components'
import { getExtensions } from '@components/RichText'

export default function PhotoIdReminderDialog({ patient, open, onClose, onSubmit }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Content patient={patient} onClose={onClose} onSubmit={onSubmit} />
    </Dialog>
  )
}

function Content({ patient, onClose, onSubmit }) {
  const editor = useEditor({
    extensions: getExtensions(),
    content: getDefaultMessage(patient),
    autofocus: 'end',
    editorProps: { clipboardTextParser },
  })

  const [handleSend, loading] = usePromiseLoading(() => {
    const message = editor.getJSON()
    return onSubmit(JSON.stringify(message))
  })

  return (
    <>
      <DialogTitle>ID Photo Reminder</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Typography variant="h4">Send a reminder message to the patient to upload their id photo.</Typography>
          <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 150, maxHeight: 400 } }]}>
            <EditorContent editor={editor} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <LoadingButton loading={loading} disabled={editor.isEmpty} variant={editor.isEmpty ? 'text' : 'contained'} onClick={handleSend}>
          Send Message
        </LoadingButton>
      </DialogActions>
    </>
  )
}

const getDefaultMessage = (patient) => {
  const uploadIdURL = QS.stringifyUrl({
    url: `/app/redirects/${PossibleRedirects.UploadId}`,
    query: { patientId: patient?.id },
  })

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [{ type: 'text', text: `Hi ${patient?.preferredName}!` }],
      },
      { type: 'paragraph' },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Telehealth regulations require that we have your photo ID on file prior to your scheduled appointment. If you could, please ',
          },
          {
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: uploadIdURL,
                  rel: 'noopener noreferrer nofollow',
                  class: null,
                },
              },
            ],
            text: 'upload your photo ID',
          },
          {
            type: 'text',
            text: " that clearly shows your picture, name, and date of birth (passport, driver's license, or other form of government ID). We're excited to provide your care, but may need to pause or reschedule until your photo ID is uploaded.",
          },
        ],
      },
      { type: 'paragraph' },
      { type: 'paragraph', content: [{ type: 'text', text: 'Instructions:' }] },
      {
        type: 'orderedList',
        attrs: { start: 1 },
        content: [
          {
            type: 'listItem',
            content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Please log in to your account.' }] }],
          },
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Once logged in, click the three lines in the upper right-hand corner to open the menu and click "',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: uploadIdURL,
                          rel: 'noopener noreferrer nofollow',
                          class: null,
                        },
                      },
                    ],
                    text: 'My Profile',
                  },
                  { type: 'text', text: '".' },
                ],
              },
            ],
          },
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  { type: 'text', text: 'Once there, you should see the option to ' },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: uploadIdURL,
                          rel: 'noopener noreferrer nofollow',
                          class: null,
                        },
                      },
                    ],
                    text: 'upload your ID',
                  },
                  { type: 'text', text: ' at the bottom of the page.' },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
